<template lang="pug">
    li.isotope-item.product.type-product.post-98.status-publish.first.instock.product_cat-for-her.has-post-thumbnail.shipping-taxable.purchasable.product-type-simple    
        div.image_frame.scale-with-grid.product-loop-thumb(ontouchstart="this.classList.toggle( 'hover'); ")
            div.image_wrapper(v-if="product.image")
               router-link(:to="{name:'product', params:{slug: product.slug} }")
                    div.mask
                    img.scale-with-grid.wp-post-image(width="600" height="444" :src="baseImageURL + 'storage' + product.image.url" alt loading="lazy")
            router-link(:to="{name:'product', params:{slug: product.slug} }")
                span.product-loading-icon.added-cart
        div.desc
            h4
                router-link(:to="{ name:'product', params:{slug: product.slug} }") {{ product.name }}
            span.price
                span.woocommerce-Price-amount.amount
                    bdi
                        template {{ product.price }}
</template>
<script>
/* eslint-disable */
import { mapActions } from 'vuex'
export default {
    props: {
        product: {
            required: true,
            type: Object
        }
    },
    data(){
        return {
            baseImageURL: window.baseImageURL
        }
    },
    computed: {
        link ()
        {
            return { 
                name: 'products-slug',
                params: {
                    slug: this.product.slug
                } 
            }
        }
    },
    methods: {
        ...mapActions({
            store: 'cart/store',
            flash: 'alert/flash'
        }),
        add (e) {
            e.preventDefault()

            this.store([{
                id: this.product.id,
                quantity: 1
            }]).then().catch(error => {
                this.flash(error.response.data.message)
            })

            this.form = {
                variation: '',
                quantity: 1,
            }
        }
    }
}
</script>

<style scoped src="@/../public/css/global.css"></style>
<style scoped src="@/../public/content/optics/css/structure.css"></style>
<style scoped src="@/../public/content/optics/css/custom.css"></style>
<style scoped src="@/../public/content/optics/css/optics.css"></style>