const auth = {
    namespaced: true,
    state: () => ({
        auth: {
            loggedIn: false
        },
        token: ''
    }),
    getters: {
        auth (state){
            return state.auth
        },
        token (state){
            return state.token
        }
    },
    mutations: {
        SET_AUTH (state, auth){
            state.auth = auth
        },
        SET_TOKEN (state, token){
            state.token = token
        }
    },
    actions: {
        async login({ commit, dispatch }) {
            let auth = window.localStorage.getItem('auth')
            if(auth != undefined){
                auth = JSON.parse(auth)
                auth.data.loggedIn = true
                commit('SET_AUTH', auth.data)
                commit('SET_TOKEN', auth.meta.token)
            }

            await this.$axios.get('auth/me').then(response => {
                response.data.data.loggedIn = true
                commit('SET_AUTH', response.data.data)
                dispatch('cart/getCart', '', { root: true })
            }).catch(errors => {
                commit('SET_AUTH', { loggedIn: false })
            })

        },
        setAuth({ commit, dispatch }, auth){
            auth.data.loggedIn = true
            commit('SET_AUTH', auth.data)
            commit('SET_TOKEN', auth.meta.token)
        },
        async logout({commit, dispatch}, {req}){
            let response  = await this.$axios.post('auth/logout').then(response => {
                window.localStorage.clear()
                console.log('Si entra')
                commit('SET_AUTH', { loggedIn: false })
            })
        }
    }
}


export default auth