import queryString from 'query-string'

const cart = {
    namespaced: true,
    state: () => ({
        changed: false,
        empty: true,
        products: [],
        shipping: null,
        subtotal: null,
        total: null
    }),
    getters: {
        changed (state){
            return state.changed
        },
        count (state){
            return state.products.length
        },
        empty (state){
            return state.empty
        },
        products (state){
            return state.products
        },
        subtotal (state){
            return state.subtotal
        },
        shipping (state){
            return state.shipping
        },
        total (state){
            return state.total
        },
    },
    mutations: {
        SET_CHANGED (state, changed){
            state.changed = changed
        },
        SET_EMPTY (state, empty){
            state.empty = empty
        },
        SET_PRODUCTS (state, products){
            state.products = products
        },
        SET_SUBTOTAL (state, subtotal){
            state.subtotal = subtotal
        },
        SET_SHIPPING (state, shipping){
            state.shipping = shipping
        },
        SET_TOTAL (state, total){
            state.total = total
        }
    },
    actions: {
        async getCart({ commit, state }) {
            let query = {}

            if(state.shipping){
                query.shipping_method_id = state.shipping.id
            }
            
            await this.$axios.get(`cart?${queryString.stringify(query)}`).then(response => {
                commit('SET_CHANGED', response.data.meta.changed)
                commit('SET_EMPTY', response.data.meta.empty)
                commit('SET_PRODUCTS', response.data.data.products)
                commit('SET_SUBTOTAL', response.data.meta.subtotal)
                commit('SET_TOTAL', response.data.meta.total)
            })
        },

        async destroy({ dispatch }, product_id) {
            await this.$axios.delete(`cart/${product_id}`).then(response => {
                dispatch('getCart')
            })

        },

        async store({ dispatch }, products) {
            await this.$axios.post('cart', {
                products
            }).then(response => {
                dispatch('getCart')
            })

        },

        async setShipping({ commit }, shipping) {
            commit('SET_SHIPPING', shipping)
        },

        async update({ dispatch },{ product_id, quantity }) {
            await this.$axios.patch(`cart/${product_id}`, {
                quantity
            }).then(response => {
                dispatch('getCart')
            })
        }
    }
}

export default cart