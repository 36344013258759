<template lang="pug">
    .container-fluid
        table.table.table-striped(v-if="$attrs.data.length")
            thead
                tr
                    th.text-center(v-for="column in columns") {{ column.label }}
                        span.fa.fa-sort(v-if="column.sortable && params.sort != column.field" @click="setSort(column)")
                        span.fa.fa-sort-down(v-if="column.sortable && params.sort == column.field && params.order == 'asc'" @click="toggleOrder()")
                        span.fa.fa-sort-up(v-if="column.sortable && params.sort == column.field && params.order == 'desc'" @click="toggleOrder()")
            tbody
                slot(v-for="client in $attrs.data" :row="client")
                    tr
        div(v-if="$attrs.data.length == 0")
            h2.text-center No existen registros
        paginate(v-model="meta.current_page" :page-count="meta.last_page" :click-handler="changePage" :prev-text="'Anterior'" :next-text="'Siguiente'" :container-class="'pagination'" :page-class="'page-item'" v-if="$attrs.data.length && meta.last_page > 1")
</template>
<style scoped>
    span.fa{
        font-size: .7rem;
        margin-left: .3rem;
        cursor: pointer;
    }
</style>
<script>
import paginate from 'vuejs-paginate'
export default {
    components: {
        paginate
    },
    data() {
        return {}
    },
    props: {
        columns: {
            type: Array,
            required: true
        },
        meta: {
            type: Object,
        },
        params: {
            type: Object,
        },
    },
    methods: {
        changePage(number){
            if(this.meta.last_page >= this.meta.current_page && this.meta.current_page > 0){
                this.params.page = number
                this.sendOut()
            }
        },
        setSort(column){
            this.params.sort = column.field,
            this.params.order = 'asc'
            this.sendOut()
        },
        toggleOrder(){
            if(this.params.order == 'asc'){
                this.params.order = 'desc'
            }else{
                this.params.order = 'asc'
            }
            this.sendOut()
        },
        sendOut(){
            this.$emit('filter', this.params)
        }
    }
}
</script>