const alert = {
    namespaced: true,
    state: () => ({
        message: null
    }),
    getters: {
        message(state)  {
            return state.message
        }
    },
    mutations: {
        SET_MESSAGE(state, message){
            state.message = message
        }
    },
    actions: {
        clear({commit}){
            commit('SET_MESSAGE', null)
        },
        flash({commit},message){
            commit('SET_MESSAGE', message)
        },
    }
}

export default alert