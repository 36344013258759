import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=25d18c1e&scoped=true&lang=pug&"
import script from "./footer.vue?vue&type=script&lang=js&"
export * from "./footer.vue?vue&type=script&lang=js&"
import style0 from "./footer.vue?vue&type=style&index=0&id=25d18c1e&scoped=true&lang=css&"
import style1 from "@/../public/css/global.css?vue&type=style&index=1&id=25d18c1e&scoped=true&lang=css&"
import style2 from "@/../public/content/optics/css/structure.css?vue&type=style&index=2&id=25d18c1e&scoped=true&lang=css&"
import style3 from "@/../public/content/optics/css/optics.css?vue&type=style&index=3&id=25d18c1e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25d18c1e",
  null
  
)

export default component.exports