<template lang="pug">
    div
        footer#Footer.clearfix
            div.widgets_wrapper.center
                div.container
                    div.row
                        div.col.col-sm-12.col-md-4
                            aside.widget_text.widget.widget_custom_html
                                h4 Opciones de pago
                                div.textwidget.custom-html-widget
                                    hr.no_line(style="margin:0 auto 25px")
                                    img(style="margin-right:20px" src="/content/optics/images/optics-footer-mastercard.png" alt)
                                    img(src="/content/optics/images/optics-footer-visa.png " alt)
                        div.col.col-sm-12.col-md-4
                            aside.widget_text.widget.widget_custom_html
                                h4 Contáctanos
                                div.textwidget.custom-html-widget
                                    hr.no_line(style="margin:0 auto 20px")
                                    p (618) 827 3377
                                        br
                                        a(href="#") contacto@opticadelnortemx.com
                        div.col.col-sm-12.col-md-4
                            aside.widget_text.widget.widget_custom_html
                                h4 Nuestra ubicación
                                div.textwidget.custom-html-widget
                                    hr.no_line(style="margin:0 auto 20px")
                                    p Bruno Martínez 144 sur
                                        br
                                        | esquina con Negrete
            div.footer_copy
                div.container
                    div.column.one
                        a#back_to_top.footer_button(href="")
                            i.fas.fa-angle-up
                        div.copyright
                            p &copy; 2021 Opticas del norte.
                            a.ml-2.btn.btn-link(href="#") Politica de privacidad
                            a.ml-2.btn.btn-link(href="#") Términos y condiciones de servicio
                            router-link.ml-2.btn.btn-link(:to="{ name: 'admin'}") Administración
                        ul.social
                            li.facebook
                                a(href="#" title="Facebook")
                                    i.fab.fa-facebook
        div#Side_slide.right.light(data-width="250")
            div.close-wrapper
                a.close(href="#")
                    i.fa.fa-times
            div.extras
                a.action_button(href="#" v-show="auth.loggedIn") Mi cuenta
                    i.fas.fa-angle-right.p-2
                a.action_button(href="#" v-show="!auth.loggedIn") Iniciar sesión
                    i.fas.fa-angle-right.p-2
                div.extras-wrapper
                    a.icon.cart.mt-3#slide-cart(href="#" v-show="auth.loggedIn")
                        i.fa.fa-shopping-bag
                        span(style="margin-left:-20px; background:rgba(255,0,0,.7); color: white; padding: 0px 8px; border-radius:50%") {{ cartCount }}
                    //- a.icon.seach.mt-3(href="#")
                        i.fa.fa-search
            div.menu_wrapper.mt-5
            ul.social.mt-4
                li.facebook
                    a(href="# " title="Facebook ")
                        i.fab.fa-facebook
        div#body_overlay
</template>
<style scoped>
    .btn-link{
        font-size: .8rem;
    }
    .mt-5{
        margin-top: 6rem !important;
    }
</style>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'Footer',
    computed: {
        ...mapGetters({
            auth: 'auth/auth',
            categories: 'categories',
            cartCount: 'cart/count'
        })
    },
    methods: {
    },
    mounted(){
        const script = document.createElement('script')
        script.src = '/js/scripts.js'
        document.body.appendChild(script)
    },
}
</script>

<style scoped src="@/../public/css/global.css"></style>;
<style scoped src="@/../public/content/optics/css/structure.css"></style>
<style scoped src="@/../public/content/optics/css/optics.css"></style>