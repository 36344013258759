const index = {
    state: () => ({
        categories: []
    }),
    getters: {
        categories (state){
            return state.categories
        }
    },
    mutations: {
        SET_CATEGORIES (state, categories){
            state.categories = categories
        }
    },
    actions: {
        async getCategories({ commit, dispatch }) {
            await this.$axios.get('categories').then(response => {
                commit('SET_CATEGORIES', response.data.data)
            })
        },
    }
}


export default index