<template lang="pug">
    div
        Navigation
        div#main-ecommerce
            slot(name="body")
        Footer
</template>
<script>
	import Navigation from './../components/navigation'
	import Footer from './../components/footer'
	export default {
		name: 'ECommerceLayout',
		components: {
			Navigation,
			Footer
		}
	}
</script>
<style scoped src="@/../public/css/global.css"></style>
<style scoped src="@/../public/content/optics/css/structure.css"></style>
<style scoped src="@/../public/content/optics/css/custom.css"></style>
<style scoped src="@/../public/content/optics/css/optics.css"></style>