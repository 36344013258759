<template lang="pug">
    div.row.single-slide.align-items-center.d-flex
        div.banner-img-full
            img.img-fluid(:src="baseImageURL + banner.image" alt="")
        div.banner-full-content
            h2(v-if="banner.title") {{ banner.title }}
            h4(v-if="banner.subtitle") {{ banner.subtitle }}
</template>
<script>
export default {
    props: {
        banner: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            baseImageURL: window.baseImageURL
        }
    }
}
</script>
<style scoped src="@/../public/css/global.css"></style>
<style scoped src="@/../public/content/optics/css/structure.css"></style>
<style scoped src="@/../public/content/optics/css/custom.css"></style>
<style scoped src="@/../public/content/optics/css/optics.css"></style>
<style scoped>
    .banner-img-full{
        height: 100vh;
        left: -10%;
        top: 0;
        width: 120%;
    }
    .banner-img-full img{
        position: absolute;
        height: 100vh;
        width: 100%;
    }
    .banner-full-content{
        position: absolute;
        width: 50%;
        top: 50%;
        left: 20%;
    }
</style>