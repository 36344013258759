import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '../middleware/auth'
import admin from '../middleware/admin'

Vue.use(VueRouter)

let index = require('./../pages/index.vue')

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import(/* webpackChunkName: "index" */ '../pages/index.vue')
  },
  {
    path: '/categoria/:slug',
    name: 'category',
    component: () => import(/* webpackChunkName: "index" */ '../pages/category.vue'),
    props: true
  },
  {
    path: '/producto/:slug',
    name: 'product',
    component: () => import(/* webpackChunkName: "index" */ '../pages/product.vue'),
    props: true
  },
  {
    path: '/registro',
    name: 'register',
    component: () => import(/* webpackChunkName: "index" */ '../pages/register.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "index" */ '../pages/login.vue'),
  },
  {
    path: '/checkout',
    name: 'checkout',
    meta: {
      middleware: auth
    },
    component: () => import(/* webpackChunkName: "index" */ '../pages/checkout.vue'),
  },
  {
    path: '/admin-panel',
    name: 'admin',
    meta: {
      middleware: [auth, admin]
    },
    component: () => import(/* webpackChunkName: "index" */ '../pages/admin/index.vue'),
  },
  {
    path: '/catalogs',
    name: 'catalogs',
    meta: {
      middleware: [auth, admin]
    },
    component: () => import(/* webpackChunkName: "index" */ '../pages/catalogs/index.vue'),
  },
  {
    path: '/catalogs/banners',
    name: 'banners',
    meta: {
      middleware: [auth, admin]
    },
    component: () => import(/* webpackChunkName: "index" */ '../pages/admin/banners/index.vue'),
  },
  {
    path: '/catalogs/categories',
    name: 'categories',
    meta: {
      middleware: [auth, admin]
    },
    component: () => import(/* webpackChunkName: "index" */ '../pages/catalogs/categories/index.vue'),
  },
  {
    path: '/catalogs/products',
    name: 'products',
    meta: {
      middleware: [auth, admin]
    },
    component: () => import(/* webpackChunkName: "index" */ '../pages/catalogs/products/index.vue'),
  },
  {
    path: '/catalogs/products/images/:slug',
    name: 'images-products',
    meta: {
      middleware: [auth, admin]
    },
    component: () => import(/* webpackChunkName: "index" */ '../pages/catalogs/products/images.vue'),
    props: true
  },
  {
    path: '/catalogs/products/stocks/:slug',
    name: 'stocks-products',
    meta: {
      middleware: [auth, admin]
    },
    component: () => import(/* webpackChunkName: "index" */ '../pages/catalogs/products/stocks/index.vue'),
    props: true
  },
  {
    path: '/products/add-stocks/:slug',
    name: 'add-stocks',
    meta: {
      middleware: [auth, admin]
    },
    component: () => import(/* webpackChunkName: "index" */ '../pages/catalogs/products/stocks/add.vue'),
    props: true
  },
  {
    path: '/products/view-stocks/:slug/:id',
    name: 'view-stocks',
    component: () => import(/* webpackChunkName: "index" */ '../pages/catalogs/products/stocks/review.vue'),
    props: true,
    meta: {
      middleware: [auth, admin]
    },
  },
  {
    path: '/catalogs/shipping-methods',
    name: 'shipping-methods',
    meta: {
      middleware: [auth, admin]
    },
    component: () => import(/* webpackChunkName: "index" */ '../pages/catalogs/shippment/index.vue'),
  },
  {
    path: '/catalogs/variations',
    name: 'variations',
    meta: {
      middleware: [auth, admin]
    },
    component: () => import(/* webpackChunkName: "index" */ '../pages/catalogs/variations/index.vue'),
  },
  {
    path: '/catalogs/products/add',
    name: 'add-products',
    meta: {
      middleware: [auth, admin]
    },
    component: () => import(/* webpackChunkName: "index" */ '../pages/catalogs/products/add.vue'),
  },
  {
    path: '/catalogs/products/edit/:slug',
    name: 'edit-products',
    meta: {
      middleware: [auth, admin]
    },
    component: () => import(/* webpackChunkName: "index" */ '../pages/catalogs/products/edit.vue'),
    props: true
  },
  {
    path: '/admin/orders',
    name: 'admin-orders',
    meta: {
      middleware: [auth, admin]
    },
    component: () => import(/* webpackChunkName: "index" */ '../pages/orders/index.vue')
  },
  {
    path: '/admin/order/:id',
    name: 'admin-order',
    meta: {
      middleware: [auth, admin]
    },
    component: () => import(/* webpackChunkName: "index" */ '../pages/orders/detail.vue'),
    props: true
  },
  {
    path: '/cart',
    name: 'cart',
    meta: {
      middleware: auth
    },
    component: () => import(/* webpackChunkName: "index" */ '../pages/cart.vue'),
    props: true
  },
  {
    path: '/orders',
    name: 'orders',
    meta: {
      middleware: auth
    },
    component: () => import(/* webpackChunkName: "index" */ '../pages/orders.vue'),
    props: true
  },
  {
    path: '/auth-signin',
    name: 'auth-signin',
    component: index
  },
  {
    path: '/categories-slug',
    name: 'categories-slug',
    component: index
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})
// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;
  
    return (...parameters) => {
      // Run the default Vue Router `next()` callback first.
      context.next(...parameters);
      // Then run the subsequent Middleware with a new
      // `nextMiddleware()` callback.
      const nextMiddleware = nextFactory(context, middleware, index + 1);
      subsequentMiddleware({ ...context, next: nextMiddleware });
    };
  }
  
router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});
export default router