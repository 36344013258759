import Vue from 'vue'
import Vuex from 'vuex'

import index from './index'
import auth from './auth'
import cart from './cart'
import alert from './alert'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        alert: alert,
        auth: auth,
        index,
        cart: cart,
    }
})

export default store