<template lang="pug">
	MainLayout
		template(slot="body")
			section.banner-area(style="background: url(/img/background.png); background-size: cover")
				div.row.fullscreen.align-items-center.justify-content-start
					div.col-lg-12
						div.active-banner-slider.owl-carousel
							ImageBanner(v-if="banners.length" :banner="banner" v-for="banner in banners" :key="banner.id")
			div#Content
				div.content_wrapper.clearfix
					div.sections_group
							div.entry-content
								div.section.mcb-section.mcb-section-42jv7ny5x(style="padding-top:55px;padding-bottom:55px")
									div.section_wrapper.mcb-section-inner
										div.wrap.mcb-wrap.mcb-wrap-eppf5u428.one.column-margin-10px.valign-top.bg-contain.clearfix
											div.mcb-wrap-inner
												div.column.mcb-column.mcb-item-kpedjvn3e.four-fifth.column_column
													div.column_attr.clearfix(style="padding:35px 0 0 0;")
														h2 Revisa nuestros últimos modelos
												div.column.mcb-column.mcb-item-5lmtal0z1.one-fifth.column_image
													div.image_frame.image_item.no_link.scale-with-grid.no_border
														div.image_wrapper
															img.scale-with-grid(src="content/optics/images/optics-home-new-icon.png")
										div.wrap.mcb-wrap.mcb-wrap-sfbtl6qn8.one.valign-top.clearfix
											div.mcb-wrap-inner
												div.column.mcb-column.mcb-item-vr3i58u78.one.column_shop
													div.woocommerce.columns-3
														div.products_wrapper.isotope_wrapper
															ul.products
																ProductThumb(v-for="product in favoriteProducts" :key="product.id" :product="product")
								div.section.mcb-section.mcb-section-1xh0alv4k.no-margin-v.dark(style="padding-bottom:80px;background-color:#5a525c;background-image:url(content/optics/images/optics-offer.png);background-repeat:no-repeat;background-position:right bottom")
									div.section_wrapper.mcb-section-inner 
										div.wrap.mcb-wrap.mcb-wrap-o42nctxgh.one.valign-top.move-up.clearfix(style="margin-top:-25px")
											div.mcb-wrap-inner
												div.column.mcb-column.mcb-item-w655wuy3r.one-third.column_placeholder
													div.placeholder /&nbsp;
												div.column.mcb-column.mcb-item-btrfc5wdg.one-third.column_button
													div.button_align.align_center
														a.button.button_full_width.button_size_2(href="categoria/unisex" style="background-color:#221e24!important;color:#fff; ")
															span.button_label
																strong VER MÁS
												div.column.mcb-column.mcb-item-5qtw8yba8.one.column_divider
													hr.no_line(style="margin:0 auto 100px; height:0px")
										div.wrap.mcb-wrap.mcb-wrap-9cfvt0coh.one-second.valign-top.clearfix(style="padding:0 2%")
											div.mcb-wrap-inner
												div.column.mcb-column.mcb-item-531qe4m64.one.column_image.optics-round
													div.image_frame.image_item.scale-with-grid.no_border
														div.image_wrapper
															a(href="/categoria/para-el")
																div.mask
																img.scale-with-grid(src="content/optics/images/optics-for-him.jpg")
															div.image_links
																a.link(href="/categoria/para-el")
																	i.icon-link
												div.column.mcb-column.mcb-item-ts1urqo19.one.column_column
													div.column_attr.clearfix.align_center(style="padding:10px 0 0 0;")
														h3 PARA ÉL
										div.wrap.mcb-wrap.mcb-wrap-eya65kigb.one-second.valign-top.clearfix(style="padding:0 2%")
											div.mcb-wrap-inner
												div.column.mcb-column.mcb-item-lsbcful5q.one.column_image.optics-round
													div.image_frame.image_item.scale-with-grid.no_border
														div.image_wrapper
															a(href="/categoria/para-ella")
																div.mask
																img.scale-with-grid(src="content/optics/images/optics-for-her.jpg")
															div.image_links
																a.link(href="/categoria/para-ella")
																	i.icon-link
												div.column.mcb-column.mcb-item-r1sd22auh.one.column_column
													div.column_attr.clearfix.align_center(style="padding:10px 0 0 0;")
														h3 Para Ella
								div.section.mcb-section.mcb-section-uoo7osrtt.no-margin-v.equal-height-wrap(style="padding-top:125px;padding-bottom:125px;background-color:#d9f5f7;background-image:url(content/optics/images/optics-home-blue-square.png);background-repeat:no-repeat;background-position:center")
									div.section_wrapper.mcb-section-inner
										div.wrap.mcb-wrap.mcb-wrap-9aj4v2wwg.two-fifth.valign-middle.clearfix
											div.mcb-wrap-inner
												div.column.mcb-column.mcb-item-cbrmcvcss.one.column_image
													div.image_frame.image_item.no_link.scale-with-grid.no_border
														div.image_wrapper
															img.scale-with-grid(src="content/optics/images/optics-home-becentre.png")
										div.wrap.mcb-wrap.mcb-wrap-nad613kou.one-second.valign-middle.clearfix(style="padding:25px 5%")
											div.mcb-wrap-inner
												div.column.mcb-column.mcb-item-0s6r1io7m.one.column_image
													div.image_frame.image_item.no_link.scale-with-grid.no_border(style="margin-bottom:15px;")
														div.image_wrapper
															img.scale-with-grid(src="content/optics/images/optics-home-abouticon.png ")
												div.column.mcb-column.mcb-item-0gif1kabn.five-sixth.column_column.column-margin-10px
													div.column_attr.clearfix
														h2 VISITA NUESTRA
															template  TIENDA EN LA CIUDAD
														hr.no_line(style="margin:0 auto 10px")
														p Atendida por profesionales siempre pensando en la salud de nuestros pacientes, visitanos te estamos esperando.
												div.column.mcb-column.mcb-item-yd4pxvy5q.three-fifth.column_button
													
								div.section.mcb-section.mcb-section-56fb530b0.no-margin-v(style="padding-top:75px;padding-bottom:75px;background-color:#d9f5f7")
									div.section_wrapper.mcb-section-inner
										div.wrap.mcb-wrap.mcb-wrap-ef710b8ad.one-second.valign-middle.bg-contain.clearfix(style="padding:40px 0 40px 5%;background-image:url(content/optics/images/optics-home-bell.png);background-repeat:no-repeat;background-position:left center")
											div.mcb-wrap-inner
												div.column.mcb-column.mcb-item-cd3161c37.one.column_column
													div.column_attr.clearfix
														h2 REGISTRATE EN NUESTRO
															br
															template NEWSLETTER
										div.wrap.mcb-wrap.mcb-wrap-e3073bf24.one-second.valign-middle.clearfix(style="padding:40px 0 40px")
											div.mcb-wrap-inner
												div.column.mcb-column.mcb-item-c60ae0c53.five-sixth.column_column
													div.column_attr.clearfix
														div#mc4wp-form-1.form.mc4wp-form
															form#newsletterform.newsletter_form
																div.column.two-third
																	input#email_news.form-control(placeholder="E-mail " type="email" name="email_news " size="40 " aria-required="true " aria-invalid="false")
																div.column.one-third
																	button.btnround#submit(onClick="return check_values_news();") Send
															br
															br
															br
															p(style="margin-left:10px; opacity:.7;") Cepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.
</template>
<style>
	@media only screen and (min-width: 1240px){
		#Top_bar.is-sticky #search_button[data-v-09d1a3b8] {
			top: 1rem !important;
		}
	}
</style>
<script>
	import MainLayout from './../layouts/ecommerce'
	import ImageBanner from './../components/general/ImageBanner'
	import ProductThumb from './../components/products/ProductThumb'
	import { mapGetters } from 'vuex'

	export default {    
		data(){
			return {
				banners: [],
				favoriteProducts: [],
				lastProducts: []
			}
		},
		components: {
			MainLayout,
			ImageBanner,
			ProductThumb
		},
		computed: {
			...mapGetters({
				categories: 'categories'
			})
		},
		mounted(){ 
			var window_width 	 = $(window).width(),
			window_height 		 = window.innerHeight,
			header_height 		 = $(".default-header").height(),
			header_height_static = $(".site-header.static").outerHeight(),
			fitscreen 			 = window_height - header_height;

			
			this.axios.get('get-banners').then(response => {
				this.banners = response.data.data
				setTimeout(() => {
					$(".active-banner-slider").owlCarousel({
						items: 1,
						autoplay: true,
						autoplayTimeout: 5000,
						loop: true,
						nav: true,
						navText:["<img src='img/banner/prev.png'>","<img src='img/banner/next.png'>"],
						dots: false
					});
				}, 200)
			})
			this.axios.get('last-products').then(response => {
				this.favoriteProducts = response.data.data
			})
			// this.axios.get('banner-products').then(response => {
			//     this.bannerProducts = response.data.data
			// })
		   
			
			// if(this.favoriteProducts.length && this.lastProducts.length){
			// 	$(".active-product-area").owlCarousel({
			// 		items:1,
			// 		autoplay:false,
			// 		autoplayTimeout: 5000,
			// 		loop:true,
			// 		nav:true,
			// 		navText:["<img src='img/product/prev.png'>","<img src='img/product/next.png'>"],
			// 		dots:false
			// 	});
			// }

			$(".fullscreen").css("height", window_height)
			$(".fitscreen").css("height", fitscreen);
		}
	}
</script>