import Vue from 'vue'
import App from './App.vue'

import iAxios from 'axios'
import VueAxios from 'vue-axios'
import store from './store/store'
import router from './router'
import MainMixin from './components/Mixins/globalFunctions'
import Swal from 'sweetalert2'
import VueForm from 'vue-form'
import underscore from 'vue-underscore';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueCurrencyFilter from 'vue-currency-filter'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// window.baseURL = 'http://ecommerce.test/api/'
window.baseURL = 'https://delnorte.dconsultores.com/api/'
// window.baseURL = 'http://delnorte_test.dconsultores.com/api/'
// window.baseImageURL = 'http://ecommerce.test/'
window.baseImageURL = 'https://delnorte.dconsultores.com/'
// window.baseImageURL = 'http://delnorte_test.dconsultores.com/'

const $axios = iAxios.create({
  // baseURL: 'http://ecommerce.test/api/',
  baseURL: 'https://delnorte.dconsultores.com/api/',
  // baseURL: 'http://delnorte_test.dconsultores.com/api/',
});

$axios.interceptors.request.use((config) => {
  const token = store.getters['auth/token'];

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});
import VTooltip from 'v-tooltip'

window.$ = jQuery
window.Swal = Swal
window.Fire = new Vue()
store.$axios = $axios;
const axios = $axios;

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.mixin(MainMixin)
Vue.component('table-component', require('./components/tools/tableComponent').default)
Vue.use(underscore);
Vue.use(VTooltip)
Vue.use(VueCurrencyFilter,
  {
    symbol : '$',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true,
    avoidEmptyDecimals: undefined,
  })
Vue.use(VueForm)
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
