import Axios from "axios"
import {_} from 'vue-underscore';

const globalFunctions = {
    data() {
        return{

        }
    },
    methods: {
        closeModal(){
            $('.modal').modal('hide');
        },
        async saveData(url, model, urlReturn, emitEvent, closemodal, clearmodel, tipoMensaje, modelOriginal, titleLoading, titleMessage, emitMessage) {
            this.openLoading('Espere por favor...')
            await this.axios.post(url, model)
                .then( response => {
                    if(response.data.status == true){
                        this.showMessage('Exito', response.data.message, 'success', urlReturn, emitMessage, emitEvent, response)
                        if(closemodal)
                            this.closeModal(closemodal)
                    }
                })
                .catch( error => {
                    this.catchError(error)
                })
        },

        async updateData(url, model, urlReturn, emitEvent, closemodal, clearmodel, tipoMensaje, modelOriginal, titleLoading, titleMessage, emitMessage) {
            this.openLoading(titleLoading)

            await this.axios.put(url, model)
            .then( response => {
                this.stopLoading()
                if (response.data.status == true) {
                    this.showMessage(titleMessage, response.data.message, 'success', urlReturn, emitMessage, emitEvent, response)
                    if(closemodal)
                        this.closeModal(closemodal)
                }
                if (response.data.status == false) {
                    this.showMessage('Error', response.data.message, 'error', '')
                }
            })
            .catch( error => {
                this.catchError(error)
            })
        },

        async deleteData(url, loadData, emitEvent, titleLoading, titleMessage) {
            Swal.fire({
                title: 'Aviso',
                text: "¿Está seguro de realizar esta acción?.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, realizarla!',
                cancelButtonText: 'Cancelar'
            })
            .then(async (result) => {
                if(result.isConfirmed){
                    this.openLoading(titleLoading)
                    await this.axios.delete(url)
                    .then((response) => {
                        if(response.data.status == true){
                            Swal.close()
                            this.showMessage(titleMessage, response.data.message, 'success', '', 1, emitEvent, response)
                            this.$emit('success')
                            if(loadData == 1){
                            }
                        }else{
                            Swal.close()
                            this.showMessage('Error', response.data.message, 'error', '')
                        }
                    })
                    .catch( error => {
                        this.catchError(error)
                    })
                }
            })
        },

        openLoading(title) {
            Swal.fire({
                title,
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        },

        catchError( error ) {
            this.stopLoading()
            let localErrors = []
            if(typeof error.response.data === 'object'){
                localErrors = _.flatten(_.toArray(error.response.data.errors));
            }else{
                localErrors = ['Algo salió mal :('];
            }

            for(let i = 0; i < localErrors.length; i++){
                this.showToast('error', localErrors[i])
            }
        },

        showMessage(title, text, icon, redirectTo, emitMessage, emitEvent, response) {
            Swal.fire({
                title,
                text,
                icon,
                willClose: () => {
                    if(redirectTo != '')
                        window.location.href = redirectTo
                    if(emitMessage == 1)
                        Fire.$emit(emitEvent, response)
                }
            })
        },
        showToast(icon, message){
            var Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
            Toast.fire({
                icon: icon,
                title: message
            })
        },
        stopLoading() {
            Swal.close()
        },
        closeModal(modal) {
            $('.modal').modal('hide')
        },
    }
}

export default globalFunctions