<template lang="pug">
	div#Top_bar
		div.container
			div.column.one
				div.top_bar_left.clearfix
					div.logo
						a#logo(href="/" title="Óptica del Norte" data-height="60" data-padding="5")
							img.logo-main.scale-with-grid(src="/img/optica_Logo.png" data-retina="/img/optica_Logo.png" data-height="25" alt="optics-logo" data-no-retina)
							img.logo-sticky.scale-with-grid(src="/img/optica_Logo.png" data-retina="/img/optica_Logo.png" data-height="25" alt="optics-logo" data-no-retina)
							img.logo-mobile.scale-with-grid(src="/img/optica_Logo.png" data-retina="/img/optica_Logo.png" data-height="25" alt="optics-logo" data-no-retina) 
							img.logo-mobile-sticky.scale-with-grid(src="/img/optica_Logo.png" data-retina="/img/optica_Logo.png" data-height="25" alt="optics-logo" data-no-retina)
					div.menu_wrapper
						nav#menu
							ul#menu-menu.menu.menu-main
								li.current-menu-item.page_item.current_page_item
									router-link(:to="{ name: 'index'}")
										span Inicio
								template(v-for="category in categories")
									li.menu-item.menu-item-type-taxonomy.menu-item-object-product_cat(:key="category.id")
										router-link.nav-link(:to="{ name: 'category', params: { slug: category.slug } }"  :key="category.slug") 
											span {{ category.name }}
								li
									router-link.nav-link(:to="{ name: 'orders' }" v-show="auth.loggedIn") 
										span Ordenes
						a.responsive-menu-toggle(href="#")
							i.fa.fa-bars
				div.top_bar_right
					div.top_bar_right_wrapper
						router-link#header_cart.mt-2(:to="{name: 'cart'}" v-show="auth.loggedIn")
							i.fa.fa-shopping-bag
							span {{ cartCount }}
						//- a#search_button.mt-2(href="#")
						//- 	i.fa.fa-search
						//- router-link.action_button(:to="{name: 'cart'}" v-show="auth.loggedIn && cartCount > 0") Comprar
						router-link.action_button(:to="{ name: 'login' }" v-show="!auth.loggedIn") Iniciar sesión
						a.action_button(@click.prevent="logout" v-show="auth.loggedIn" href="#") Logout
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            auth: 'auth/auth',
            categories: 'categories',
            cartCount: 'cart/count'
        })
    },
    methods: {
        ...mapActions({
			getCategories: 'getCategories',
			login: 'auth/login',
            logout: 'auth/logout'
        })
    },
    mounted(){
		this.login()
		this.getCategories()
    },
}
</script>

<style scoped src="@/../public/css/global.css"></style>;
<style scoped src="@/../public/content/optics/css/structure.css"></style>
<style scoped src="@/../public/content/optics/css/custom.css"></style>
<style scoped src="@/../public/content/optics/css/optics.css"></style>